<template>
	<v-container class="pt-0" fluid>
		<div class="text-h5">项目列表</div>
		<div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>
		<v-row no-gutters>
			<v-col cols="6">
				<template v-if="$route.params.type !== undefined">
					<v-row no-gutters>
						<v-col class="text-center" cols="2" style="font-size:14px;line-height:40px">
							<strong>状态统计：</strong>
						</v-col>
						<v-col cols="10">
							<v-row no-gutters>
								<v-col class="text-center" cols="4">
	            <span class="text-truncate" style="font-size:14px;line-height:40px">
	              <span>{{ status_count_name }} - 待处理 : {{
			              status_count[0]
		              }}</span>
	            </span>
								</v-col>
								<v-col class="text-center" cols="4" style="font-size:14px;line-height:40px">
	            <span v-if="statusArr[0] !== statusArr[1]" class="text-truncate">
	             <span> {{ status_count_name }} - 进行中 : {{
			             status_count[1]
		             }}</span>
	            </span>
								</v-col>
								<v-col class="text-center" cols="4">
	            <span class="text-truncate" style="font-size:14px;line-height:40px">
	             <span> {{ status_count_name }} - 已完成 : {{
			             status_count[2]
		             }}</span>
	            </span>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</template>
			</v-col>
			<v-col cols="6">
				<div class="text-end">
					<v-btn class="mr-2" color="blue" dark outlined @click="exportData(0)">导出原始数据</v-btn>
					<v-btn color="blue" dark outlined @click="exportData(1)">导出筛选数据</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<v-card class="mt-5">
					<Table ref="table" :columns="table_headers" :data="projectList" border highlight-row>
						<template slot="assigned_10_user" slot-scope="{ row, index }">
							{{ row.assigned_10_user ? row.assigned_10_user.join(',') : '' }}
						</template>
						<template slot="assigned_30_user" slot-scope="{ row, index }">
							{{ row.assigned_30_user ? row.assigned_30_user.join(',') : '' }}
						</template>
						<template slot="action" slot-scope="{ row, index }">
							<v-btn color="primary" small @click="toDetails(row.id)">详情</v-btn>
						</template>
					</Table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>

import {getProjectList, getProjectLogs, updateProject} from "@/api/projects";
import {getBuildingTypeList, getTypeList} from "@/api/dicts";
import {getTestItemAssignments} from "@/api/testitems";
import {getUserList} from "@/api/users";
import moment from "moment";
export default {
	name: "projects",
	methods: {
		exportData(type) {
			if (!type) {
				this.$refs.table.exportCsv({
					filename: '项目列表原始数据_' + this.dateFormat(moment(), 'short')
				});
			} else {
				this.$refs.table.exportCsv({
					filename: '项目列表筛选数据_' + this.dateFormat(moment(), 'short'),
					original: false
				});
			}
		},
		//筛选项目状态
		project_status_search(project_status) {
			let search_text = project_status >= this.statusArr[2] ? '已完成' : (project_status === this.statusArr[0] ? '待处理' : '进行中')
			//console.log('status', search_text)
			this.project_list_search = search_text
		},
		//处理表格扩展显示对数据
		table_headers_handle() {
			let default_headers = [
				{
					title: "#",
					type: "index",
					key: "index",
					width: 50,
					align: "center",
					fixed: "left",
				},
				{
					title: "项目名称",
					key: "name",
					align: "center",
					width: 160,
					fixed: "left",
				},
				{
					title: "建筑类别",
					key: "building_type",
					width: 150,
					align: "center",
					sortable: true,
					filters: [{label: '居住建筑', value: 1}, {label: '公共建筑', value: 2}],
					filterMultiple: false,
					filterMethod(value, row) {
						if (value === 1) {
							return row.building_type === '居住建筑';
						} else if (value === 2) {
							return row.building_type === '公共建筑';
						}
					}
				},
				{
					title: "建筑详细类别",
					key: "sub_building_type",
					width: 150,
					align: "center",
					sortable: true,
				},
				{
					title: "建筑面积",
					key: "building_area",
					width: 150,
					align: "center",
				},
				{
					title: "项目地址",
					key: "address",
					width: 250,
					align: "center",
				},
				{
					title: "项目联系人",
					key: "contact",
					width: 150,
					align: "center",
				},
				{
					title: "项目进度",
					key: "project_status",
					width: 150,
					align: "center",
					sortable: true
				},
			]
			let res = [
				{
					title: '星级', key: 'review_20_level', sortable: true,
					filters: [{label: '一星', value: 1}, {label: '二星', value: 2}, {label: '三星', value: 3}],
					filterMultiple: false,
					filterMethod(value, row) {
						if (value === 1) {
							return review_20_level == 1;
						} else if (value === 2) {
							return review_20_level == 2;
						} else if (value === 3) {
							return review_20_level == 3;
						}
					}
				},
				{title: '得分', key: 'review_score', sortable: true},
				{
					title: '获证时间', key: 'review_20_time',
					filters: [{label: '一年内', value: 1}, {label: '两年内', value: 2}, {label: '三年内', value: 3}, {
						label: '三年前',
						value: 4
					}],
					filterMultiple: false,
					filterMethod(value, row) {
						let review_time = moment(row.review_20_time)
						if (value > 3) {
							let limit_time = moment().subtract(3, 'years')
							//console.log('-3',limit_time)
							return (review_time < limit_time) && row.review_20_time;
						} else {
							let limit_time = moment().subtract(value, 'years')
							//console.log('3',limit_time)
							return (review_time > limit_time) && row.review_20_time;
						}
					}
				},
				{title: '证书有效期', key: 'review_20_limit'},
				{title: '受理人员', key: 'review_1_user'},
				{title: '审核方案管理人员', key: 'log_10_user'},
				{title: '审核人员', key: 'assigned_10_user', slot: 'assigned_30_user'},
				{title: '初审人员', key: 'review_13_user'},
				{title: '复核人员', key: 'review_15_user'},
				{title: '认证决定人员', key: 'review_17_user'},
				{title: '认证决定结论', key: 'review_17_result'},
				{title: '监督审核人员', key: 'assigned_30_user', slot: 'assigned_30_user'},
				{title: '监督初审人员', key: 'review_33_user'},
				{title: '监督复审人员', key: 'review_35_user'},
				{title: '监督认证决定人员', key: 'review_37_user'},
				{title: '监督认证决定结论', key: 'review_37_result'},
			]
			let pages = {
				//信息申请
				page_0: [],
				//认证受理页面
				page_1: [
					{title: '申请人', key: 'log_1_user'},
					{title: '申请日期', key: 'log_1_time'},
					{title: '受理人员', key: 'review_1_user'},
					{title: '受理日期', key: 'review_1_time'},
				],
				//专家分配页面
				page_2: [
					{title: '受理人员', key: 'review_1_user'},
					{title: '受理日期', key: 'review_1_time'},
					{title: '审核方案管理人员', key: 'log_10_user'},
					{title: '审核人员', key: 'assigned_10_user', slot: 'assigned_10_user'},
				],
				//评审页面
				page_3: [
					{title: '受理人员', key: 'review_1_user'},
					{title: '受理日期', key: 'review_1_time'},
					{title: '审核方案管理人员', key: 'log_10_user'},
					{title: '审核人员', key: 'assigned_10_user', slot: 'assigned_10_user'},
				],
				//初审页面
				page_4: [
					{title: '受理人员', key: 'review_1_user'},
					{title: '审核方案管理人员', key: 'log_10_user'},
					{title: '审核人员', key: 'assigned_10_user', slot: 'assigned_10_user'},
					{title: '初审人员', key: 'review_13_user'},
					{title: '初审结论', key: 'review_13_result'},
					{title: '初审时间', key: 'review_13_time'},
				],
				//复审页面
				page_5: [
					{title: '受理人员', key: 'review_1_user'},
					{title: '审核方案管理人员', key: 'log_10_user'},
					{title: '审核人员', key: 'assigned_10_user', slot: 'assigned_10_user'},
					{title: '初审人员', key: 'review_13_user'},
					{title: '复核人员', key: 'review_15_user'},
					{title: '复核结论', key: 'review_15_result'},
					{title: '复核时间', key: 'review_15_time'},
				],
				//认证决定页面
				page_6: [
					{title: '受理人员', key: 'review_1_user'},
					{title: '审核方案管理人员', key: 'log_10_user'},
					{title: '审核人员', key: 'assigned_10_user', slot: 'assigned_10_user'},
					{title: '初审人员', key: 'review_13_user'},
					{title: '复核人员', key: 'review_15_user'},
					{title: '认证决定人员', key: 'review_17_user'},
					{title: '认证决定结论', key: 'review_17_result'},
					{title: '认证决定时间', key: 'review_17_time'},
				],
				//监督专家分配页面
				page_8: [
					{
						title: '获证时间', key: 'review_20_time',
						filters: [{label: '一年内', value: 1}, {label: '两年内', value: 2}, {label: '三年内', value: 3}, {
							label: '三年前',
							value: 4
						}],
						filterMultiple: false,
						filterMethod(value, row) {
							let review_time = moment(row.review_20_time)
							if (value > 3) {
								let limit_time = moment().subtract(3, 'years')
								//console.log('-3',limit_time)
								return (review_time < limit_time) && row.review_20_time;
							} else {
								let limit_time = moment().subtract(value, 'years')
								//console.log('3',limit_time)
								return (review_time > limit_time) && row.review_20_time;
							}
						}
					},
					{title: '证书有效期', key: 'review_20_limit'},
					{title: '审核方案管理人员', key: 'log_30_user'},
					{title: '监督审核人员', key: 'assigned_30_user', slot: 'assigned_30_user'},
				],
				//监督评价页面
				page_9: [
					{
						title: '获证时间', key: 'review_20_time',
						filters: [{label: '一年内', value: 1}, {label: '两年内', value: 2}, {label: '三年内', value: 3}, {
							label: '三年前',
							value: 4
						}],
						filterMultiple: false,
						filterMethod(value, row) {
							let review_time = moment(row.review_20_time)
							if (value > 3) {
								let limit_time = moment().subtract(3, 'years')
								//console.log('-3',limit_time)
								return (review_time < limit_time) && row.review_20_time;
							} else {
								let limit_time = moment().subtract(value, 'years')
								//console.log('3',limit_time)
								return (review_time > limit_time) && row.review_20_time;
							}
						}
					},
					{title: '证书有效期', key: 'review_20_limit'},
					{title: '审核方案管理人员', key: 'log_30_user'},
					{title: '监督审核人员', key: 'assigned_30_user', slot: 'assigned_30_user'},
				],
				//监督初审页面
				page_10: [
					{
						title: '获证时间', key: 'review_20_time',
						filters: [{label: '一年内', value: 1}, {label: '两年内', value: 2}, {label: '三年内', value: 3}, {
							label: '三年前',
							value: 4
						}],
						filterMultiple: false,
						filterMethod(value, row) {
							let review_time = moment(row.review_20_time)
							if (value > 3) {
								let limit_time = moment().subtract(3, 'years')
								//console.log('-3',limit_time)
								return (review_time < limit_time) && row.review_20_time;
							} else {
								let limit_time = moment().subtract(value, 'years')
								//console.log('3',limit_time)
								return (review_time > limit_time) && row.review_20_time;
							}
						}
					},
					{title: '证书有效期', key: 'review_20_limit'},
					{title: '审核方案管理人员', key: 'log_30_user'},
					{title: '监督审核人员', key: 'assigned_30_user', slot: 'assigned_30_user'},
					{title: '监督初审人员', key: 'review_33_user'},
					{title: '监督初审结论', key: 'review_33_result'},
					{title: '监督初审时间', key: 'review_33_time'},
				],
				//监督复审页面
				page_11: [
					{
						title: '获证时间', key: 'review_20_time',
						filters: [{label: '一年内', value: 1}, {label: '两年内', value: 2}, {label: '三年内', value: 3}, {
							label: '三年前',
							value: 4
						}],
						filterMultiple: false,
						filterMethod(value, row) {
							let review_time = moment(row.review_20_time)
							if (value > 3) {
								let limit_time = moment().subtract(3, 'years')
								//console.log('-3',limit_time)
								return (review_time < limit_time) && row.review_20_time;
							} else {
								let limit_time = moment().subtract(value, 'years')
								//console.log('3',limit_time)
								return (review_time > limit_time) && row.review_20_time;
							}
						}
					},
					{title: '证书有效期', key: 'review_20_limit'},
					{title: '审核方案管理人员', key: 'log_30_user'},
					{title: '监督审核人员', key: 'assigned_30_user', slot: 'assigned_30_user'},
					{title: '监督初审人员', key: 'review_33_user'},
					{title: '监督复审人员', key: 'review_35_user'},
					{title: '监督复审结论', key: 'review_35_result'},
					{title: '监督复审时间', key: 'review_35_time'},
				],
				//监督认证决定页面
				page_12: [
					{
						title: '获证时间', key: 'review_20_time',
						filters: [{label: '一年内', value: 1}, {label: '两年内', value: 2}, {label: '三年内', value: 3}, {
							label: '三年前',
							value: 4
						}],
						filterMultiple: false,
						filterMethod(value, row) {
							let review_time = moment(row.review_20_time)
							if (value > 3) {
								let limit_time = moment().subtract(3, 'years')
								//console.log('-3',limit_time)
								return (review_time < limit_time) && row.review_20_time;
							} else {
								let limit_time = moment().subtract(value, 'years')
								//console.log('3',limit_time)
								return (review_time > limit_time) && row.review_20_time;
							}
						}
					},
					{title: '证书有效期', key: 'review_20_limit'},
					{title: '审核方案管理人员', key: 'log_30_user'},
					{title: '监督审核人员', key: 'assigned_30_user', slot: 'assigned_30_user'},
					{title: '监督初审人员', key: 'review_33_user'},
					{title: '监督复审人员', key: 'review_35_user'},
					{title: '监督认证决定人员', key: 'review_37_user'},
					{title: '监督认证决定结论', key: 'review_37_result'},
					{title: '监督认证决定时间', key: 'review_37_time'},
				],
				//证书管理页面
				page_13: [
					{title: '申请方', key: 'log_1_user'},
					{title: '得分', key: 'review_score'},
					{
						title: '星级',
						key: 'review_20_level',
						sortable: true,
						filters: [{label: '一星', value: 1}, {label: '二星', value: 2}, {label: '三星', value: 3}],
						filterMultiple: false,
						filterMethod(value, row) {
							if (value === 1) {
								return review_20_level == 1;
							} else if (value === 2) {
								return review_20_level == 2;
							} else if (value === 3) {
								return review_20_level == 3;
							}
						}
					},
					{
						title: '获证时间', key: 'review_20_time',
						filters: [{label: '一年内', value: 1}, {label: '两年内', value: 2}, {label: '三年内', value: 3}, {
							label: '三年前',
							value: 4
						}],
						filterMultiple: false,
						filterMethod(value, row) {
							let review_time = moment(row.review_20_time)
							if (value > 3) {
								let limit_time = moment().subtract(3, 'years')
								//console.log('-3',limit_time)
								return (review_time < limit_time) && row.review_20_time;
							} else {
								let limit_time = moment().subtract(value, 'years')
								//console.log('3',limit_time)
								return (review_time > limit_time) && row.review_20_time;
							}
						}
					},
					{title: '证书有效期', key: 'review_20_limit'},
					{
						title: '证书状态', key: 'review_20_status', sortable: true,
						filters: [{label: '签发', value: 1}, {label: '暂停', value: 2}, {label: '恢复', value: 3}, {
							label: '注销',
							value: 4
						}],
						filterMultiple: false,
						filterMethod(value, row) {
							if (value === 1) {
								return row.review_20_status == 1;
							} else if (value === 2) {
								return row.review_20_status == 2;
							} else if (value === 3) {
								return row.review_20_status == 3;
							} else if (value === 4) {
								return row.review_20_status == 4;
							}
						}
					},
					{title: '证书变更原因', key: 'log_20_text'},
					{title: '证书处理人员', key: 'log_20_user'},
					{title: '证书处理时间', key: 'log_20_time'},
				],
			};
			let headers = this.$route.params.type ? (pages['page_' + this.$route.params.type].concat([{
				title: "状态",
				key: "project_status_text",
				width: 120,
				align: "center",
				fixed: "left",
				filters: [{label: '待处理', value: '待处理'}, {label: '进行中', value: '进行中'}, {label: '已完成', value: '已完成'}],
				filterMultiple: false,
				filterMethod(value, row) {
					return row.project_status_text == value;
				}
			},
			])) : res
			headers.map((item, index) => {
				if (!item.width) {
					headers[index] = {...item, width: 140, align: "center",}
				}
			})
			return default_headers.concat(headers).concat([{
				title: "操作",
				slot: 'action',
				key: 'action',
				width: 90,
				align: "center",
				fixed: "right",
			}])
		},
		//获取项目列表数据
		async getProjectList() {
			let logs = [];
			let assignments = [];
			let status_arr = this.pageStatusArr();
			//获取项目操作流程记录
			await getProjectLogs({page: 1, pagesize: 10000}).then((res) => {
				logs = res.results;
				// console.log('logs', logs)
			});
			//获取项目专家分配列表
			await getTestItemAssignments({page: 1, pagesize: 10000}).then((res) => {
				assignments = res.results;
				// console.log('assignments ', assignments)
			});
			getProjectList({page: 1, pagesize: 10000}).then((res) => {
				let projectList = [];
				let status_count = [0, 0, 0];
				// console.log('list',res.results)
				res.results.map((item) => {
					let projectData = JSON.parse(JSON.stringify(item));
					let reviews = projectData.reviews;
					let project_status = projectData.project_status.value
					//清除软删除或者project_status异常的数据
					if (projectData.base_status && project_status !== undefined) {
						let isUse = false;
						logs.map((logItem) => {
							let from_status = logItem.from_status.value;
							let to_status = logItem.to_status.value;
							if (projectData.id === logItem.project) {
								projectData['log_' + to_status + '_user'] = logItem.user.username
								projectData['log_' + to_status + '_time'] = this.dateFormat(logItem.update_time)
								//根据project_status判断当前页面是否需要使用这条数据
								if (status_arr.includes(to_status)) {
									isUse = true;
								}
							}
						});
						if (isUse || !this.$route.params.type) {
							if (this.$route.params.type && project_status === status_arr[0]) {
								status_count[0] += 1;
								projectData['project_status_text'] = '待处理'
							} else if (this.$route.params.type && project_status === status_arr[1]) {
								status_count[1] += 1;
								projectData['project_status_text'] = '进行中'
							} else if (this.$route.params.type) {
								status_count[2] += 1;
								projectData['project_status_text'] = '已完成'
							}
							projectData['project_status'] = projectData['project_status'].text
							let building_type = this.buildingTypeHandle(projectData['building_type'], projectData['sub_building_type'])
							projectData['building_type'] = building_type[0]
							projectData['sub_building_type'] = building_type[1]
							//加入评审信息
							let review_score = 0;
							reviews.map(reviewItem => {
								//获取 初审/复审/认证评价 数据
								if (reviewItem.test_item === null) {
									projectData['review_' + reviewItem.project_status + '_user'] = this.usernameHandle(reviewItem.expert)
									projectData['review_' + reviewItem.project_status + '_time'] = this.dateFormat(reviewItem.update_time)
									projectData['review_' + reviewItem.project_status + '_result'] = reviewItem.test_result ? '通过' : '不通过'
									projectData['review_' + reviewItem.project_status + '_text'] = reviewItem.test_text
									projectData['review_' + reviewItem.project_status + '_status'] = reviewItem.test_text
									projectData['review_' + reviewItem.project_status + '_limit'] = this.dateFormat(reviewItem.test_time)
									projectData['review_' + reviewItem.project_status + '_level'] = reviewItem.test_value
								}
								//汇总 评审总分
								if (reviewItem.test_item && (reviewItem.project_status === 11) && reviewItem.test_score) {
									review_score += parseInt(reviewItem.test_score)
								}
							})
							projectData['review_score'] = review_score
							//加入专家分配信息
							assignments.map(assignItem => {
								if (assignItem.project === projectData.id) {
									if (!projectData['assigned_' + assignItem.project_status + '_user']) {
										projectData['assigned_' + assignItem.project_status + '_user'] = []
									}
									let username = this.usernameHandle(assignItem.user)
									if (!projectData['assigned_' + assignItem.project_status + '_user'].includes(username)) {
										projectData['assigned_' + assignItem.project_status + '_user'].push(username)
									}
								}
							})
							projectList.push(projectData);
						}
					}
				});
				this.projectList = projectList;
				this.table_expanded = projectList;
				this.status_count = status_count;
				this.status_count_name_handler();
				// console.log('projectList', projectList)
			});
		},

		//页面数据处理
		pageStatusArr() {
			//各页面需要展示的待完成/进行中/已完成 project_status
			let page_type = this.$route.params.type;
			let pages = {
				//认证受理页面
				page_0: [18, 18, 20],
				//认证受理页面
				page_1: [1, 1, 2],
				//专家分配页面
				page_2: [3, 3, 10],
				//评审页面
				page_3: [10, 11, 12],
				//初审页面
				page_4: [12, 13, 14],
				//复审页面
				page_5: [14, 15, 16],
				//认证决定页面
				page_6: [16, 17, 18],
				//监督专家分配页面
				page_8: [20, 20, 30],
				//监督评价页面
				page_9: [30, 31, 32],
				//监督初审页面
				page_10: [32, 33, 34],
				//监督复审页面
				page_11: [34, 35, 36],
				//监督认证决定页面
				page_12: [36, 37, 38],
				//认证受理页面
				page_13: [18, 18, 20],
			};
			let arr = [];
			if (page_type) {
				arr = pages["page_" + page_type] ? pages["page_" + page_type] : [];
			} else {
				arr = [
					1,
					2,
					3,
					10,
					11,
					12,
					13,
					14,
					15,
					16,
					17,
					18,
					20,
					31,
					32,
					33,
					34,
					35,
					36,
					37,
					38,
				];
			}
			this.statusArr = arr;
			return arr;
		},

		//获取字典列表
		getTypeList() {
			getTypeList({pagesize: 10000}).then((res) => {
				this.typeList = res.results;
				// console.log('typeList', res.results)
			});
		},
		//获取建筑类型列表
		getBuildingTypeList() {
			getBuildingTypeList({pagesize: 10000}).then((res) => {
				this.buildingTypeList = res;
				// console.log('building_type', res)
			});
		},
		//获取用户列表
		getUserList() {
			getUserList({pagesize: 10000}).then((res) => {
				this.userList = res.results;
				// console.log('userList', res.results)
			})
		},
		//跳转到详情页
		toDetails(id) {
			this.$router.push({name: "project_details", params: {id}});
		},
		//格式化时间
		dateFormat(date, type = "short") {
			let format = "YYYY-MM-DD HH:mm:ss";
			if (type === "short") {
				format = "YYYY-MM-DD";
			}
			return moment(date).format(format);
		},
		//获取媒体
		getMediaObject(projectItem, category) {
			let files = projectItem.files.filter((fileItem) => {
				return fileItem.category === category;
			});
			// console.log(category, files)
			return files;
		},
		status_count_name_handler() {
			let nameList = {
				//认证受理页面
				page_13: "证书管理",
				//认证受理页面
				page_1: "认证受理",
				//专家分配页面
				page_2: "评价专家分配",
				//评审页面
				page_3: "评价",
				//初审页面
				page_4: "初审",
				//复审页面
				page_5: "复审",
				//认证决定页面
				page_6: "认证决定",
				//监督专家分配页面
				page_8: "监督员分配",
				//监督评价页面
				page_9: "监督评价",
				//监督初审页面
				page_10: "监督初审",
				//监督复审页面
				page_11: "监督复审",
				//监督认证决定页面
				page_12: "监督认证决定",
			};
			let page_type = this.$route.params.type;
			this.status_count_name = nameList["page_" + page_type];
		},
		//字段列表处理
		typeListHandle(type) {
			let list = [];
			this.typeList.map((item) => {
				if (item.key == type) {
					list.push(item);
				}
			});
			return list;
		},
		//建筑列表处理
		buildingTypeHandle(building_type, sub_building_type) {
			let list = [];
			let list_sub = []
			list = this.buildingTypeList.filter(item => item.id === building_type)
			if (list[0]) {
				list_sub = list[0].children.filter(item => item.id === sub_building_type)
			}
			return [list[0] ? list[0].name : '', list_sub[0] ? list_sub[0].name : '']
		},
		//用户名处理
		usernameHandle(userid) {
			let user = this.userList.filter(user => user.id === userid)
			return user[0] ? user[0].username : ''
		}
	},
	watch: {
		//路由参数变化时，更新当前页面的project_list 列表
		"$route.params.type"() {
			this.project_list_search = '';
			this.getProjectList();
		},
	},
	data() {
		return {
			//当前登陆用户信息
			user: JSON.parse(localStorage.getItem("user")),
			//页面类型
			pageType: this.$route.params.type,
			//当前页面project_status列表
			statusArr: [],
			typeList: [],
			buildingTypeList: [],
			userList: [],
			//获取所有项目列表
			projectList: [],
			//表格中筛选项目状态
			project_list_search: '',
			table_expanded: [],
			table_headers: this.table_headers_handle(),
			table_data: [],
			status_count: [0, 0, 0],
			status_count_name: "",
		};
	},
	async mounted() {
		// console.log('user', this.user)
		await this.getTypeList();
		await this.getBuildingTypeList();
		await this.getUserList()
		await this.getProjectList();
	},
};
</script>
<style lang="stylus">
.v-breadcrumbs li:nth-child(even) {
	padding: 0 2px !important;
}
</style>
